<template>
<!-- :theme-color="'primary'" -->
<td class="k-command-cell">
    <kbutton
        style="background-color:rgb(225 119 0); color:white"
        class="k-grid-edit-command"
        @click="viewHandler"
    >
        <!-- Edit -->
        <i class="fa fa-eye"></i>
    </kbutton>
    <kbutton v-if="UserID"
        style="background-color:#3297fa; color:white"
        class="k-grid-edit-command"
        @click="editHandler"
    >
        <!-- Edit -->
        <i class="fa fa-edit"></i>
    </kbutton>

    <kbutton v-if="UserID"       
        :theme-color="'primary'"
        class="k-grid-remove-command"
        @click="removeHandler"
    >
        <!-- Remove -->
        <i class="fa fa-trash"></i>
    </kbutton>
    <kbutton
        style="background-color:green; color:white"
        class="k-grid-remove-command"
        @click="downloadHandler"
    >
        <!-- Download -->
        <i class="fa fa-download"></i>
    </kbutton>
</td>
</template>
<script>
import { Button } from '@progress/kendo-vue-buttons';

export default {
    components: {
      'kbutton': Button
    },
    data(){
        return{
            UserID : false
        }
    },
    mounted(){
        var userDetails = sessionStorage.getItem("sessionDetails");
        if (userDetails===null || userDetails==""){
        window.location.href = '#/login';
        } else {
        
        var t = JSON.parse(userDetails) //atob(udata)
        if(t.userID == "ceo@careercraffers.com"){
            this.UserID = true
        }
        this.SessionID = t.SessionId
        if(this.SessionID == "" || this.SessionID == undefined || this.SessionID == null){
            window.location.href = '#/login';
        }
        } 
    },
   props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    methods: {
        editHandler: function() {
            this.$emit('edit', this.dataItem);
        },
        removeHandler: function() {
            // if(confirm('Confirm deleting: ' + this.dataItem.ProductName)){
               this.$emit('remove', this.dataItem);
            // }
        },
        downloadHandler: function() {
            this.$emit('download', this.dataItem);
        },
        viewHandler: function() {
            this.$emit('view', this.dataItem);
        },
    }
}
</script>