import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Icons from "../views/Icons.vue";
import shows from "../views/shows.vue";
import Distributor from "../views/Distributor.vue";
import GenderMaster from "../views/GenderMaster.vue";
import Sitesettings from "../views/Sitesettings.vue";
import JobtitleMaster from "../views/JobtitleMaster.vue";
import Designation from "../views/DesignationMaster.vue";
import DistrictMaster from "../views/DistrictMaster.vue";
import ExperienceMaster from "../views/ExperienceMaster.vue";
import QualificationMaster from "../views/QualificationMaster.vue";
import VerifyOTP from "../views/VerifyOTP.vue";
import StateMaster from "../views/StateMaster.vue";
import NewApplication from "../views/NewApplication.vue";
import UserManagement from "../views/UserManagement.vue";
import UserWiseDownloadList from "../views/UserWiseDownloadList.vue";
import ActivityLog from "../views/ActivityLog.vue";
import AppImport from "../views/AppImport.vue";
import CtcRange from "../views/CtcRange.vue";

import DataEntry from "../views/DataEntry.vue";
import approvedentry from "../views/approvedentry.vue";
import submitedentry from "../views/submitedentry.vue";
import myapprovedentry from "../views/myapprovedentry.vue";
import pendingapproval from "../views/pendingapproval.vue";
import sequencefinder from "../views/sequencefinder.vue";




import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import AddNewApplication from "../views/AddNewApplication.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/newapplication",
        name: "newapplication",
        components: { default: NewApplication },
      },
      {
        path: "/Users",
        name: "icons",
        components: { default: Icons },
      },
      {
        path: "/shows",
        name: "shows",
        components: { default: shows },
      },
      {
        path: "/distributor",
        name: "distributor",
        components: { default: Distributor },
      },
      {
        path: "/gendermaster",
        name: "gendermaster",
        components: { default: GenderMaster },
      },
      {
        path: "/sitesettings",
        name: "sitesettings",
        components: { default: Sitesettings },
      },
      {
        path: "/jobtitlemaster",
        name: "jobtitlemaster",
        components: { default: JobtitleMaster },
      },
      {
        path: "/designationmaster",
        name: "designationmaster",
        components: { default: Designation },
      },
      {
        path: "/districtmaster",
        name: "districtmaster",
        components: { default: DistrictMaster },
      },
      {
        path: "/stateMaster",
        name: "stateMaster",
        components: { default: StateMaster },
      },
      {
        path: "/experiencemaster",
        name: "experiencemaster",
        components: { default: ExperienceMaster },
      },
      {
        path: "/qualificationmaster",
        name: "qualificationmaster",
        components: { default: QualificationMaster },
      },      
      {
        path: "/appimport",
        name: "appimport",
        components: { default: AppImport },
      },
      {
        path: "/activitylog",
        name: "activitylog",
        components: { default: ActivityLog },
      },
      {
        path: "/usermanagement",
        name: "usermanagement",
        components: { default: UserManagement },
      },
      {
        path: "/userwisedownloadlist",
        name: "userwisedownloadlist",
        components: { default: UserWiseDownloadList },
      },
      {
        path: "/dataentry",
        name: "dataentry",
        components: { default: DataEntry },
      },
      {
        path: "/submitedentry",
        name: "submitedentry",
        components: { default: submitedentry },
      },
      {
        path: "/approvedentry",
        name: "approvedentry",
        components: { default: approvedentry },
      },
      {
        path: "/myapprovedentry",
        name: "myapprovedentry",
        components: { default: myapprovedentry },
      },
      {
        path: "/pendingapproval",
        name: "pendingapproval",
        components: { default: pendingapproval },
      },
      {
        path: "/sequencefinder",
        name: "sequencefinder",
        components: { default: sequencefinder },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
      {
        path: "/verifyotp",
        name: "verifyotp",
        components: { default: VerifyOTP },
      },
      {
        path: "/addnewapplication",
        name: "addnewapplication",
        components: { default: AddNewApplication },
      },
      {
        path: "/ctcrange",
        name: "ctcrange",
        components: { default: CtcRange },
      },
    ],
  },
  {
    path: "/addnewapplication",
    redirect: "addnewapplication",
    component: AddNewApplication,
    // children: [      
    //   {
    //     path: "/addnewapplication",
    //     name: "addnewapplication",
    //     components: { default: AddNewApplication },
    //   },
    // ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
  base:"ap"
});

export default router;
