<template>
   <div class="top-navbar">
      <nav class="navbar navbar-light justify-content-between">
      <h1><a class="navbar-brand">Dashboard</a></h1>
      <form class="form-inline">
        <!-- <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button> -->
        
      </form>
    </nav>
  </div>
  <div class="form-row" style="margin-left:35px">
    <div class="form-group col-md-3">
      <label for="inputEmail4">Start Date</label><datepicker 
      :style="{padding:'5px'}"
      :value="fromdate"
      :format="'dd-MMM-yyyy'"
      @change="startDateChange"
      /> 
    </div>
    <div class="form-group col-md-3">
      <label for="inputEmail4">End Date</label><datepicker 
      :style="{padding:'5px'}"
      :value="enddate"
      :format="'dd-MMM-yyyy'"
      @change="endDatehandleChange"
      />
    </div>  
    <!-- <button type="button" class="btn btn-primary" @click="loadusers()" style="margin:30px; background-color: rgb(50 151 250);"><i class="fa fa-refresh"></i></button>    -->
  </div>
  <base-header style="background-color:#f8f9fe !important; ">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Applications"
            type="gradient-red"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="h2 font-weight-bold mb-0">
                <!-- <i class="fa fa-arrow-up"></i> 3.48% -->
                {{Totalcount}}
              </span> <br>
              <span style="float:right; margin-right: -40px">Current Period</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Applications"
            type="gradient-orange"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="h2 font-weight-bold mb-0">
                <!-- <i class="fa fa-arrow-up"></i> 12.18% -->
                {{Previousmonthcount}}
              </span> <br>
              <span style="float:right; margin-right: -40px">Last Period</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Male"
            type="gradient-green"
            icon="fa fa-male"
            class="mb-4 mb-xl-0"
          > 
          
            <!-- sub-title="924" -->
            <template v-slot:footer>
              <span class="h2 font-weight-bold mb-0">
                {{Total_male}}
                <!-- <i class="fa fa-arrow-down"></i> 5.72% -->
              </span> <br>
              <span style="float:right; margin-right: -40px">Current Period</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total Female"
            type="gradient-info"
            icon="fa fa-female"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="h2 font-weight-bold mb-0">
                {{Total_female}}
                <!-- <i class="fa fa-arrow-up"></i> 54.8% -->
              </span> <br>
              <span style="float:right; margin-right: -40px">Current Period</span>
            </template>
          </stats-card>
        </div>
      </div>
  </base-header>
  <div class="row" style="margin:20px">
  <div class="col-md-6" >
    <div class="card">
      <div class="card-body">
        <div class="col-md-12" style="text-align:center; margin-bottom: 25px;">Job Applied For</div>        
          <Chart>
            <!-- <ChartTitle :text="'Job Applied For'" /> -->
            <ChartLegend :position="'bottom'" />
            <ChartSeries>
              <ChartSeriesItem
                :type="'pie'"
                :data-items="pieData"
                :field="'Total'"
                :category-field="'_id'"
                :labels="{ visible: true, content: labelContent }"
              />
            </ChartSeries>
          </Chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="col-md-12" style="text-align:center; margin-bottom: 25px;">Work Location</div>  
          <Chart>
            <!-- <ChartTitle :text="'WorkLocation'" /> -->
            <ChartLegend :position="'bottom'" />
            <ChartSeries>
              <ChartSeriesItem
                :type="'donut'"
                :data-items="WorkLocData"
                :field="'Total'"
                :category-field="'_id'"
                :labels="{ visible: true, content: WorkLoclabel }"
              />
            </ChartSeries>
          </Chart>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import helper from "../helper.js"
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  
} from "@progress/kendo-vue-charts";
import { DatePicker } from '@progress/kendo-vue-dateinputs';
import "hammerjs";

export default {
  components: {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    'datepicker': DatePicker,
  },
  created(){
    
  },
  data: function () {
    return {
      fromdate : new Date(),
      enddate: new Date(),
      Totalcount : 0,
      Previousmonthcount : 0,
      Total_male : 0,
      Total_female : 0,
      WorkLocData : [],
      pieData: [],
    };
  },
  mounted(){
    var d = new Date();
    var currMonth = d.getMonth();
    var currYear = d.getFullYear();
    var startDate = new Date(currYear, currMonth, 1);
    this.fromdate = startDate
    this.loadusers()
  },
  methods: {
    startDateChange(event){
      this.fromdate = event.target.value;
      this.loadusers()
    },
    endDatehandleChange(event){
      this.enddate = event.target.value;
      this.loadusers()
    },
    labelContent(props) {
      // let formatedNumber = Number(props.dataItem.Total).toLocaleString(
      //   undefined,
      //   {
      //     style: "percent",
      //     minimumFractionDigits: 2,
      //   }
      // );
      return `${props.dataItem._id}  ${props.dataItem.Total}`;
    },
    WorkLoclabel(wl){
      return `${wl.dataItem._id}  ${wl.dataItem.Total}`;
    },
    loadusers(){
       const article = {
        fromdate: this.fromdate,
        enddate : this.enddate
       };
        this.axios.post(helper.SERVICEURL+"dashboard/get", article) //header
          .then(response => {
            if(response.data){
              this.WorkLocData= response.data.Data.WorkLocation
              this.pieData = response.data.Data.JobAppliedfor
              this.Totalcount = response.data.Data ? response.data.Data.Totalcount : 0
              this.Previousmonthcount = response.data.Data.Previousmonthcount ? response.data.Data.Previousmonthcount : 0
              response.data.Data.GenderCount.forEach(element => {
                if(element._id == "FEMALE"){
                  this.Total_female = element.Total
                }else if(element._id == "MALE"){
                  this.Total_male = element.Total
                }
              });                           
            }      
          });

    },
  },
};
</script>