<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <!-- short-title="Argon"
      title="Argon" -->
    <side-bar
      :background-color="sidebarBackground"
      
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-white',
            path: '/dashboard',
          }"
        />
        <sidebar-item       
          :link="{
            name: 'Applications',
            icon: 'ni ni-send text-white',
            path: '/newapplication',
          }"
        />
        <!-- <sidebar-item       
          :link="{
            name: 'CTC Range',
            icon: 'ni ni-send text-white',
            path: '/ctcrange',
          }"
        /> -->
        <!-- <sidebar-item       
          :link="{
            name: 'Add Data',
            icon: 'ni ni-fat-add text-white',
            path: '/distributor',
          }"
        /> -->

        <!-- <sidebar-item       
          :link="{
            name: 'Gender Master',
            icon: 'ni ni-circle-08 text-white',
            path: '/gendermaster',
          }"
        />        
        <sidebar-item       
          :link="{
            name: 'Job Category Master',
            icon: 'ni ni-basket text-white',
            path: '/jobtitlemaster',
          }"
        />
        <sidebar-item       
          :link="{
            name: 'District Master',
            icon: 'ni ni-badge text-white',
            path: '/districtmaster',
          }"
        />
        <sidebar-item       
          :link="{
            name: 'State Master',
            icon: 'ni ni-compass-04 text-white',
            path: '/statemaster',
          }"
        />
        <sidebar-item       
          :link="{
            name: 'Qualification Master',
            icon: 'ni ni-hat-3 text-white',
            path: '/qualificationmaster',
          }"
        />
        <sidebar-item       
          :link="{
            name: 'Experience Master',
            icon: 'ni ni-chart-bar-32 text-white',
            path: '/experiencemaster',
          }"
        /> -->

        
        <!-- v-if="IsAdmin == 'ceo@careercraffers.com'"  -->
         <sidebar-item
         v-if="UserID"              
          :link="{
            name: 'User Management',
            icon: 'ni ni-single-02 text-white',
            path: '/usermanagement',
          }"
        />
        <sidebar-item
        v-if="UserID"         
          :link="{
            name: 'Activity Log',
            icon: 'ni ni-box-2 text-white',
            path: '/activitylog',
          }"
        />
        <!--<sidebar-item       
          :link="{
            name: 'User Tracking',
            icon: 'ni ni-square-pin text-white',
            path: '/dashboard',
          }"
        /> -->
        <sidebar-item          
          :link="{
            name: 'Logout',
            icon: 'ni ni-button-power text-white', //text-red
            path: '/login',
          }"
        />

        <!-- <sidebar-item
         v-if="UR=='Super-Admin'"
          :link="{
            name: 'User Management',
            icon: 'ni ni-single-02 text-blue',
            path: '/users',
          }"
        />
        <sidebar-item
         v-if="UR=='Super-Admin'"
          :link="{
            name: 'Show Management',
            icon: 'ni ni-watch-time text-orange',
            path: '/shows',
          }"
        />
        <sidebar-item
         v-if="UR=='Super-Admin'"
          :link="{
            name: 'Distributor Management',
            icon: 'ni ni-single-02 text-yellow',
            path: '/distributor',
          }"
        />
        <sidebar-item
         v-if="UR=='Super-Admin'"
          :link="{
            name: 'Data Management and Sequence Finder',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/sequencefinder',
          }"
        />
        <sidebar-item
         v-if="UR=='Super-Admin'"
          :link="{
            name: 'Reports',
            icon: 'ni ni-single-copy-04 text-info',
            path: '/login',
          }"
        />
        <sidebar-item
         v-if="UR=='Quality-Checker'"
          :link="{
            name: 'Pending Approval',
            icon: 'ni ni-single-copy-04 text-info',
            path: '/pendingapproval',
          }"
        />
        <sidebar-item
         v-if="UR=='Quality-Checker'"
          :link="{
            name: 'Approved Entries',
            icon: 'ni ni-check-bold  text-info',
            path: '/myapprovedentry',
          }"
        />
        <sidebar-item
         v-if="UR=='Data-Entry-Operator'"
          :link="{
            name: 'Data Entry',
            icon: 'ni ni-bullet-list-67 text-info',
            path: '/dataentry',
          }"
        />
        <sidebar-item
         v-if="UR=='Data-Entry-Operator'"
          :link="{
            name: 'Submitted Entries',
            icon: 'ni ni-single-copy-04 text-info',
            path: '/submitedentry',
          }"
        />
        <sidebar-item
         v-if="UR=='Data-Entry-Operator'"
          :link="{
            name: 'Approved Entries',
            icon: 'ni ni-check-bold text-info',
            path: '/approvedentry',
          }"
        /> -->
        
       

       
      </template>
      <template v-slot:drpdwn>
        <div class="form-group col-md-4">              
          <label for="inputEmail4">CTC Range</label>
          <dropdownlist
          :class="col-md-6"
          :style="{padding:'5px'}"
          :data-items='CtcRangeList'
          >
          </dropdownlist>              
        </div>
      </template>


    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <!-- <dashboard-navbar></dashboard-navbar> -->

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      </div>
    </div>
  </div>
</template>
<script>
// import DashboardNavbar from "./DashboardNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import helper from "../helper.js"

export default {
  components: {
    // DashboardNavbar,
    // ContentFooter,
  },
  data() {
    return {
      UR:helper.UR,
      sidebarBackground: "white", //vue|blue|orange|green|red|primary
      SessionID : "",
      UserID : false,
      IsAdmin : helper.IsAdmin,
      bool : true
    };
  },
  beforeCreate(){
    //helper.checklogin()
  },
  mounted(){
    var userDetails = sessionStorage.getItem("sessionDetails");
    if (userDetails===null || userDetails==""){
       window.location.href = '#/login';
    } else {
      var t = JSON.parse(userDetails) //atob(udata)
      if(t.userID == "ceo@careercraffers.com"){
        this.UserID = true
      }
      this.SessionID = t.SessionId
      if(this.SessionID == "" || this.SessionID == undefined || this.SessionID == null){
        window.location.href = '#/login';
      }
    } 
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
