<template>
  <div>
  <div>
  <div class="card shadow" v-if="!CloseFlag">
    <div class="card-body">
      <div>
        <form>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Name</label>
              <input type="text" v-model="Name" class="form-control" name="Name" placeholder="Enter Name">      
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Mail ID</label>
              <input type="text" v-model="MailId" v-bind:style="Isemailexists ? {'border':'solid 1px red'}:{}" v-on:blur="isemailexsit" class="form-control" name="MailId" placeholder="Enter Mail ID"> 
              <span v-if="Isemailexists" class="text-danger">Email id already exists!</span>   
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Gender</label>              
            <div>
              <dropdownlist
              :style="{padding:'5px'}"
              :data-items='GenderList'
              v-model="Gender"
              >
              </dropdownlist>
            </div>
            
            </div>
          </div>
          <div class="form-row">            
            <div class="form-group col-md-4">
              <label for="inputEmail4">Phone Number</label>
              <input type="text" v-model="PhoneNumber" class="form-control" name="PhoneNumber" placeholder="Enter Phone Number">  
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Work Location</label>
            <div>
              <dropdownlist v-if="!IsWorklocationOthers"
              :style="{padding:'5px'}"
              :filterable="true"
              @filterchange="filterChange"
              :data-items='WorkLocationList'
              v-model="WorkLocation"
              @change="workLocchange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsWorklocationOthers">
                <input type="text" v-model="WorkLocation" placeholder="Enter Work Location" class="form-control" name="Name">
                <button
                type="reset"
                class="is-active"
                @click="WorkLocation='', IsWorklocationOthers=false"
              >
                x
              </button>
              </div>
            </div>
            </div>
            <div class="form-group col-md-4">
              <label for="inputEmail4">Qualification</label>
              <div>
              <dropdownlist v-if="!IsQualification"
              :style="{padding:'5px'}"
              :filterable="true"
              @filterchange="QualfilterChange"
              :data-items='QualificationList'
              v-model="Qualification"
              @change="QualChange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsQualification">
              <div>
                <input type="text" v-model="Qualification" placeholder="Enter Qualification" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="Qualification='', IsQualification=false"
              >
                x
              </button>
            </div>            
            </div>
            </div>
            
            </div>                
          </div> 
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Job Category</label>
              <div>
              <dropdownlist v-if="!IsJobCategory"
              :style="{padding:'5px'}"
              :data-items='JobCategoryList'
              :filterable="true"
              @filterchange="JobfilterChange"
              v-model="JobCategory"
              @change="JobCategoryChange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsJobCategory">
              <div>
                <input type="text" v-model="JobCategory" placeholder="Enter Job Category" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="JobCategory='',  Designation='', IsJobCategory=false"
              >
                x
              </button>
            </div>
            </div>
            </div>
            </div>
             <div class="form-group col-md-4">
              <label for="inputEmail4">Designation</label>
              <div>
              <dropdownlist v-if="!IsJobCategory"
              :style="{padding:'5px'}"
              :data-items='DesignationList'
              v-model="Designation"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsJobCategory">
              <div>
                <input type="text" v-model="Designation" placeholder="Enter Designation"  class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="JobCategory='', Designation='', IsJobCategory=false"
              >
                x
              </button>
            </div>
            </div>
            </div>
            </div>
               <div class="form-group col-md-4">
              <label for="inputEmail4">Experience</label>              
              <div>
              <dropdownlist v-if="!IsExperience"
              :style="{padding:'5px'}"
              :data-items='ExperienceList'
              v-model="Experience"
              @change="ExepChange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsExperience">
              <div>
                <input type="text" v-model="Experience" placeholder="Enter Experience" class="form-control" name="Name">
                <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="Experience='', IsExperience=false"
              >
                x
              </button>
            </div>            
            </div>
            </div>
            </div>   
             
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputEmail4">Date of Birth</label>
              <!-- <datepicker 
              :style="{padding:'5px'}"
              :value="DOB"
              :format="'dd-MMM-yyyy'"
              @change="DOBhandleChange"
             />         -->

            <mydatepicker  @myfunction="setdob"  :value="DOB" v-model="DOB" :id="'Datepicker'" />

             <!-- <input v-model="DOB" name="date" class="form-control" type="text"  id="datedate" readonly="readonly"> -->
            </div>
            <div class="form-group col-md-4" >
              <label for="inputEmail4">Preferred Location</label>
              <multiselect
              :style="{padding:'5px'}"
              :filterable="true"
              @filterchange="PreffilterChange"
              :data-items='PreferredLocationList'
              v-model="PreferredLocation"
              >
              </multiselect>
            </div>   
               <div class="form-group col-md-4">              
              <label for="inputEmail4">Upload Resume</label>
              <div style="padding:5px;">
                <input @change="handlefile"  type="file">
              </div>              
            </div> 
                
           
          </div> 
          <div class="form-row">
             <div class="form-group col-md-4">              
              <label for="inputEmail4">CTC Range</label>
              <dropdownlist v-if="!IsCtcRange"
              :style="{padding:'5px'}"
              :data-items='CtcRangeList'
              v-model="CtcRange"
              @change="ctcchange"
              >
              </dropdownlist>
              <div class="form-group col-md-12" style="margin:0px;padding:0px" v-if="IsCtcRange">              
            <input type="text" v-model="CtcRange" placeholder="Enter Ctc Range"  class="form-control" name="JobAppliedfor">
            <button
                title="Click me to clear the input field"
                type="reset"
                class="is-active"
                @click="CtcRange='', IsCtcRange=false"
              >
                x
              </button>
            </div>              
            </div>
            <div class="form-group col-md-4">
                <label for="inputEmail4">Technical Skills</label>
                <input type="text" v-model="TechnicalSkillsComma" class="form-control" name="JobAppliedfor" placeholder="Enter Comma For Multiple Technical Skills"> 
            </div>
             <div class="form-group col-md-4">
              <label for="inputEmail4">Job Applied For</label>
              <input type="text" v-model="JobAppliedfor" class="form-control" name="JobAppliedfor" placeholder="Enter Job Applied For">  
            </div>  
             
          </div>
          
          <div class="form-row" v-if = "Experience != 'Fresher'">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Employment History</label>
              <table class="table table-striped table-sm table-bordered">
              <thead>
                <tr>
                  <th class="form-control-sm">Organization Name</th>
                  <th class="form-control-sm">Start Date</th>
                  <th class="form-control-sm">End Date</th>
                  <th class="form-control-sm">Designation</th>
                  <th class="form-control-sm text-center">Present</th>
                  <th class="form-control-sm text-center">Action Item</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in EmploymentHistory" v-bind:key="i">
                  <td>
                    <input type="text"  v-model="d.PreOrganizationName" class="form-control" name="JobAppliedfor" placeholder="Previous Organization Name"> 
                  </td>
                  <td>
                    <!-- <input type="date" onkeydown="return false" id="datemin" name="datemin" v-model="d.StartDate"
                    style="padding:5px">    -->
                      <mydatepicker  :id="'Datepickerstart'+i"  @myfunction="e => d.StartDate=e"   :value="d.StartDate" v-model="d.StartDate" />
                  </td>
                  <td>
                    <!-- <input type="date" onkeydown="return false" id="datemin" name="datemin" v-model="d.EndDate"
                    style="padding:5px"> -->
                     <mydatepicker  :id="'Datepickerend'+i"  @myfunction="e => d.EndDate=e" :value="d.EndDate" v-model="d.EndDate" />              
                  </td>
                  <td>
                    <input type="text" v-model="d.PreDesignation" class="form-control" name="JobAppliedfor" placeholder="Previous Designation"> 
                  </td>
                  <td class="text-center"> 
                    <input v-model="d.Present" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="RemoveRange(i)"
                      v-if="EmploymentHistory.length > 1"                      
                      >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="AddRange(i)"
                      v-if="i == EmploymentHistory.length - 1"
                      >
                      <i class="fa fa-plus"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              </table>  
            </div>           
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Comments (If any)</label>           
              <textarea class="form-control" v-model="Candidatecommands" id="exampleFormControlTextarea1" rows="1"></textarea>
            </div>           
          </div> 
          <div class="form-row">
            <div class="form-group col-md-12">
               <recaptcha ref="recaptcha" @verify="onVerify"></recaptcha>
            </div>           
          </div> 

            

          <div v-if="PageLoading">
            <div class="col-12" style="text-align: right;">      
              <div class="loader--ripple" style="width:57px !important">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <button v-if="!PageLoading" type="button" class="btn btn-success" style="float: right;"  @click="save(false)" >Save</button>
        </form>
      </div>
    </div>
      
  </div>
  </div>
  
  <div v-if="CloseFlag" style="height:50vh; text-align:center;">
    <h2>Your application saved successfully</h2>
  </div>    
    
    <transition name="error">
      <div id="maxalertclient" v-if="maxalert"> {{max}} <i class="far fa-times-circle" @click="maxalert=false"></i></div>
    </transition>
  </div>
</template>

<script>
import helper from "../helper.js"
import { DropDownList } from '@progress/kendo-vue-dropdowns';
// import { DatePicker } from '@progress/kendo-vue-dateinputs';
import '@progress/kendo-theme-default/dist/all.css';
import { MultiSelect } from '@progress/kendo-vue-dropdowns';
import swal from 'sweetalert';
import Recaptcha from '../components/Recaptcha.vue'
import Mydatepicker from '../components/Mydatepicker.vue'
import { filterBy } from '@progress/kendo-data-query';
import moment from "moment"


export default {
  components: {
    'dropdownlist': DropDownList,
    // 'datepicker': DatePicker,
    'multiselect': MultiSelect,
    Recaptcha,
    Mydatepicker
  },
  data() {
    return {
      Isemailexists:false,
      imnotroboot:false,
      PageLoading:false,
      CloseFlag:false,
      Name : "",
      MailId : "",
      PhoneNumber : "",
      WorkLocation : "",
      Qualification : "",
      JobCategory:"",
      Designation : "",
      Experience : "",
      JobAppliedfor : "",
      applicationID:"",
      Gender:"",
      IsActive: true,
      maxalert:false,
      max:0,
      Mode:"NEW",
      list:[],
      DOB: "",
      TechnicalSkillsComma : "",
      TechnicalSkills : [],
      Candidatecommands : "",
      file_max_size : 1064000,
      GenderList:[],
      QualificationList:["Other"],
      QualificationListMain:["Other"],
      ExperienceList:["Other"],
      WorkLocationList : ["Other"],
      MainWorkLocationList : ["Other"],
      PreferredLocationList : [],
      PreferredLocation : [],
      DesignationList:[],
      JobCategoryList:["Other"],
      JobCategoryListMain:["Other"],
      isadd:false,
      file:"",
      FileName : "",      
      EmploymentHistory:[],
       CtcRangeList : [
      "Other",
      "0 lakh - 0.6 lakh",
      "0.6 lakh - 1.2 lakh",
      "1.2 lakh - 2.4 lakh",
      "2.4 lakh - 3.6 lakh",
      "3.6 lakh - 4.8 lakh",
      "4.8 lakh - 6 lakh",
      "6 lakh - 7 lakh",
      "7 lakh - 8 lakh",
      "8 lakh - 9 lakh",
      "9 lakh - 10 lakh",
      "10 lakh - 12 lakh",
      "12 lakh - 15 lakh",
      "15 lakh - 18 lakh",
      "18 lakh - 20 lakh",
      "20 lakh - 25 lakh",
      "25 lakh - 30 lakh",
      "30 lakh+"
      ],
      CtcRange : "",
      errorFlag : false,
      IsWorklocationOthers:false,
      IsCtcRange:false,
      IsQualification:false,
      IsExperience:false,
      IsJobCategory:false,
    };
  },
    mounted () {
  //   var vm = this
  //   $('#datedate').each(function(e){
  //     e.datepicker({
  //      changeMonth: true,
  //   changeYear: true,
  //    language: "en",
  //     onSelect: function(dateText) { 
  //       vm.date = dateText
  //     }
  //  })
  //   })
},
   created: function () {
     this.loadusers();
     this.genderList();
     this.qualificationList();
     this.experienceList();
     this.workLocationList();
     this.designationList();
     this.AddRange()
  },
  watch:{
    TechnicalSkillsComma(newval, oldval){
      if(oldval != newval){
        var temp = newval.split(",")
        this.TechnicalSkills = temp
      }
    }

  },  
  
  methods: {
    isemailexsit(){
        const article = {"MailId":this.MailId};
        var self =this
         this.axios.post(helper.SERVICEURL+"applications/isemailexists", article) //header
          .then(response => {
            if(response.data){
                var tempdata = response.data          
                if (tempdata.Isemailexists)   {
                    self.Isemailexists = true
                    this.errorFlag = true
                    return this.error("Email id already exists!")
                } else {
                  self.Isemailexists = false
                }        
            }      
          });
    },
    setdob(e){
      this.DOB =moment(e,'DD-MM-YYYY')
    },
    filterChange (event) {
      this.WorkLocationList = this.filterData(event.filter);
    },
    filterData(filter) {
        const data = this.MainWorkLocationList;
        return filterBy(data, filter);
    },
    PreffilterChange (event) {
      this.PreferredLocationList = this.PreffilterData(event.filter);
    },
    PreffilterData(filter) {
        const data = this.MainWorkLocationList;
        return filterBy(data, filter);
    },     
    JobfilterChange (event) {
      this.JobCategoryList = this.JobfilterData(event.filter);
    },
    JobfilterData(filter) {
        const data = this.JobCategoryListMain;
        return filterBy(data, filter);
    },
    QualfilterChange (event) {
      this.QualificationList = this.QualfilterData(event.filter);
    },
    QualfilterData(filter) {
        const data = this.QualificationListMain;
        return filterBy(data, filter);
    },
    JobCategoryChange(event){
      if(event.value == "Other"){
        this.IsJobCategory = true
        this.JobCategory = ""
        this.Designation = ""
      }else{
        this.IsJobCategory = false
        //his.value = event.value;
        var  self = this
         self.DesignationList = []
         self.Designation=""
       const article = {"jobcategory.jobtitle":event.value};
      //  const header = {header : {SessionId: "113"}}
        this.axios.post(helper.SERVICEURL+"designation/get", article) //header
          .then(response => {
            if(response.data){
                var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.designation
                self.DesignationList.push(temp)
              })               
            }      
          });
        }
    },
    onVerify(response) {
      console.log("response--->",response)
      if (response) {
          this.imnotroboot = true;
      } 
    },
    validate(){
      this.errorFlag = false
      return new Promise(resolve => {
          // //eslint-disable-next-line
      const regexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (this.Name==""){
          this.errorFlag = true
          return this.error("Name is required!")
        }        
        if (this.MailId==""){
          this.errorFlag = true
          return this.error("Email is required!")
        } else if (this.Isemailexists) {
           this.errorFlag = true
          return this.error("Email id already exists!!")
        } else if (!this.MailId.match(regexp)){
          this.errorFlag = true
          return this.error("Email is not valid!")
        }
        if (this.Gender==""){
          this.errorFlag = true
          return this.error("Gender is required!")
        } 
        if (this.PhoneNumber==""){
          return this.error("Phone number is required!")
        } else if (this.PhoneNumber.length!=10){
          return this.error("Phone number is not valid!")
        }
        if (this.WorkLocation==""){
          this.errorFlag = true
          return this.error("WorkLocation is required!")
        }
        if (this.Qualification==""){
          this.errorFlag = true
          return this.error("Qualification is required!")
        }
        if (this.JobCategory==""){
          this.errorFlag = true
          return this.error("Job Category is required!")
        }
        if (this.Designation==""){
          this.errorFlag = true
          return this.error("Designation is required!")
        }
        if (this.Experience==""){
          this.errorFlag = true
          return this.error("Experience is required!")
        }
         if (this.DOB== ""){
          this.errorFlag = true
          return this.error("Date of Birth is required!")
        }
        if (this.PreferredLocation == ""){
          this.errorFlag = true
          return this.error("Preferred Location is required!")
        }
        if (this.TechnicalSkillsComma == ""){
          this.errorFlag = true
          return this.error("Technical Skills is required!")
        }
        if (this.JobAppliedfor==""){
          this.errorFlag = true
          return this.error("Job Applied for is required!")
        }      
        
        if (this.CtcRange == ""){
          this.errorFlag = true
          return this.error("CTC Range is required!")
        }
        
        if(this.Experience!="Fresher"){          
          this.EmploymentHistory.forEach(x=>{            
            if(x.PreOrganizationName == undefined || x.PreOrganizationName == "" ||
              x.StartDate == undefined || x.StartDate == "" ||
              x.EndDate == undefined || x.EndDate == "" ||
              x.PreDesignation == undefined || x.PreDesignation == ""){
              this.errorFlag = true
              this.failFlag = true
              return this.error("All Fields in Employment History is Required !")              
            }
            if(this.failFlag){              
              return false
            }            
          })
        }
        if (!this.imnotroboot){
          this.errorFlag = true
          return this.error("Verify that you are not a Robot!")
        }
        setTimeout(() => {
          console.log("this.errorFlag", this.errorFlag)
          if(!this.errorFlag){
            resolve (true)
          }else{
            resolve (false)
          }          
        }, "500")
      });
    },
    error(text){
      var _this = this
      if (this.maxalert){
         this.maxalert = false
      }
       this.max = text
         this.maxalert = true
         setTimeout(function(){
                    _this.maxalert = false;
                }, 3000);
                return false;
    },
      isNumber (evt){
    const keysAllowed= ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed = evt.key;
    
    if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
    }
    },
    loadusers(){
      this.Mode="NEW"
       const article = {};
      //  const header = {header : {SessionId: "113"}}
        this.axios.post(helper.SERVICEURL+"applications/get", article) //header
          .then(response => {
            if(response.data){
              this.list= response.data.Data                
            }      
          });

    },

    DOBhandleChange(event){
      this.DOB = event.target.value;
    },

    genderList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"gender/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data            
              tempdata.forEach(x=>{
                let temp = x.gender
                self.GenderList.push(temp)
              })
            }      
          });
    },

    qualificationList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"qualification/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.qualification
                self.QualificationListMain.push(temp)
                self.QualificationList.push(temp)
              })           
            }      
          });
    },

    experienceList(){
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"experience/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.experience
                self.ExperienceList.push(temp)
              })                 
            }      
          });
    },

    workLocationList(){
      var self = this;
       const article = {};       
        this.axios.post(helper.SERVICEURL+"district/get", article)
          .then(response => {
            if(response.data){        
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.district
                self.MainWorkLocationList.push(temp)
                self.WorkLocationList.push(temp)
                self.PreferredLocationList.push(temp)
              })                
            }      
          });
    },

    designationList(){
      console.log("@@@@@designationList")
      var self = this;
       const article = {};
        this.axios.post(helper.SERVICEURL+"jobtitle/get", article)
          .then(response => {
            if(response.data){
              var tempdata = response.data          
              tempdata.forEach(x=>{
                let temp = x.jobtitle
                self.JobCategoryList.push(temp)
                self.JobCategoryListMain.push(temp)
              })                
            }      
          });
    },

    handlefile(e) {
      const selectedfile= e.target.files[0]; // get first file
      this.createBase64Image(selectedfile);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var fileBase64 = e.target.result;
        this.FileName = fileObject.name;
        this.file = fileBase64.split(",")[1]
      };
      reader.readAsDataURL(fileObject);
    },

    async save(){
    
        const result = await this.validate();
        console.log("result", result)
        if(!result){
          return
        }
      

     var temparr = []
      if(this.TechnicalSkills.length > 0){
        this.TechnicalSkills.forEach(x=>{
          var tempstr = "" 
          tempstr = x.replace(/^\s+|\s+$/gm,'')
          temparr.push(tempstr)
        })
      }
       const article = {
        "Gender":this.Gender,
        "Name":this.Name,
        "MailId":this.MailId,
        "PhoneNumber":this.PhoneNumber,
        "WorkLocation":this.WorkLocation,
        "PreferredLocation":this.PreferredLocation,
        "Qualification":this.Qualification,
        "Designation":this.Designation,
        "JobCategory":this.JobCategory,
        "Experience":this.Experience,
        "JobAppliedfor":this.JobAppliedfor,
        "Filebase": this.file,
        "filename":this.FileName,
        "DOB": this.DOB,
        "TechnicalSkills": temparr,
        "Candidatecommands":this.Candidatecommands,
        "EmploymentHistory" : this.EmploymentHistory,
        "CtcRange" : this.CtcRange,
        "IsWorklocationOthers" : this.IsWorklocationOthers,
        "IsCtcRange" : this.IsCtcRange,
        "IsQualification" : this.IsQualification,
        "IsExperience" : this.IsExperience,
        "IsJobCategory" : this.IsJobCategory,
        };
       var Actionurl= "applications/create"
        this.PageLoading = true
        this.axios.post(helper.SERVICEURL+Actionurl, article)
          .then(response => {
            this.PageLoading = false
            if(response.data.status){              
               swal({ 
                  buttons: false, 
                  text: response.data.Msg,
                  timer:2000, 
                }); 
               this.CloseFlag = true
            }  else {
              swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,
                }); 
            }     
          });

    },    
    RemoveRange(i) {
      var self = this;
      if (self.EmploymentHistory.length == 0) {
        return;
      }
      if (i == undefined) {
        i = self.EmploymentHistory.length - 1;
      }
      self.EmploymentHistory.splice(i, 1);
    },
    AddRange() { 
      var condcheck=true
      // this.EmploymentHistory.forEach((v) => {
      // })
      if (condcheck){
        if (this.EmploymentHistory.length == 0) {
          var tmp = {
            
          };
          this.EmploymentHistory.push(tmp);
        } else {
          tmp = {
            
          };
          this.EmploymentHistory.push(tmp);
        }
      }
    },
    ctcchange(e){
      if(e.value == "Other"){
        this.CtcRange = ""
        this.IsCtcRange=true
      }else{
        this.IsCtcRange=false
      }
    },
    workLocchange(e){
      if(e.value == "Other"){
        this.IsWorklocationOthers = true
        this.WorkLocation = ""
      }else{
        this.IsWorklocationOthers = false
      }
    },
    QualChange(e){
      if(e.value == "Other"){
        this.IsQualification = true
        this.Qualification = ""
      }else{
        this.IsQualification = false
      }
    },
    ExepChange(e){
      if(e.value == "Other"){
        this.IsExperience = true
        this.Experience = ""
      }else{
        this.IsExperience = false
      }
    }
  },
};
</script>

<style>
#maxalertclient {
  position: fixed;
    padding: 20px;
    z-index: 10000;
    color: #fff;
    background: #bf0909;
    bottom: 200px;
    right: 10px;
    border-radius: 10px;
}
</style>
