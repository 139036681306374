<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card"> <!--bg-secondary-->
        <div class="card-header"> <!--bg-transparent-->
          <div class="text-muted text-center mt-2 mb-3">
            <!-- <h1 style="color : black">CAREER CRAFFERS</h1> -->
            <img :src="logo" class="navbar-brand-img" alt="..." style="max-height:4rem; width:14rem" /> 
          </div>
         
        </div>
        <div class="card-body px-lg-5 py-lg-5">
          <!-- <div class="text-center text-muted mb-4">
            <small>sign in with credentials</small>
          </div> -->
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="User Name"
              addon-left-icon="ni ni-email-83"
              v-model="UserName"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="Password"
            >
            </base-input>

            <input type="checkbox" style="width: 15px;height: 15px;margin-right: 5px;" v-model="remomber"/>
            <span class="text-muted"> Remember me</span>
            <div class="text-center">
              <!-- <base-button type="primary" class="my-4" @click="login">Sign in</base-button> -->
             <button v-if="!PageLoading" type="button"  class="my-4 btn" style="background-color:#3297fa; color:white" v-on:click="login">Sign in</button>
             <div v-if="PageLoading">
              <div class="my-4 btn" style="text-align: right;">      
                <div class="loader--ripple" style="width:57px !important">
                  <div></div>
                  <div></div>
                </div>
              </div>
             </div>
             <!-- <button type="button"  class="my-4 btn" style="background-color:#3297fa; color:white" v-on:click="addNewApp">Add New Applicaion</button> -->
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <!-- <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div> -->
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            >
            <!-- <small>Create new account</small> -->
            </router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../helper.js"
import swal from 'sweetalert';

export default {
  name: "login",
  data() {
    return {
      logo : "img/brand/logo-new.jpeg",
      PageLoading:false,
      UserInfo:{},
      remomber:false,
      Password : "",
      UserName : "",
      PresentTime : new Date()
    };
  },
  created(){
    var udata = localStorage.getItem("UUID");
    if (udata===null || udata==""){
      //do nothing
    } else {
      var t = JSON.parse(udata) //atob(udata)
      this.UserName=t.email
      this.Password= t.password
      this.remomber = true
    }
    this.loadsitesettings()
  },
 methods: {
    addNewApp(){
      window.location.href = '#/addnewapplication';
    },
    loadsitesettings() {
        var Actionurl = "user/getsitesettings"
        const article = {};
        this.axios.post(helper.SERVICEURL+Actionurl, article)
        .then(response => {
          if(response.data) {            
              localStorage.setItem("sitesettings",JSON.stringify(response.data))
          }    
      });      
    },
    login() {
        var ToOtpPage = false
        var ToDashBoard = false    
        var Actionurl = "user/login"
        const article = {"username":this.UserName,"password":this.Password};
        this.PageLoading = true
        this.axios.post(helper.SERVICEURL+Actionurl, article)
        .then(response => {
          if(response.data.status) {
            this.PageLoading = false
            if(response.data.Msg == "successfully Logged In!"){
              if(this.UserName == 'ceo@careercraffers.com'){
                helper.IsAdmin = true
              }else{
                var NonAdmin = localStorage.getItem("OTPData");
                  if (NonAdmin===null || NonAdmin==""){
                    ToOtpPage = true
                  } else {
                    var nonAdmin = JSON.parse(NonAdmin)
                    var TimeinLocal = nonAdmin.TimeNow.split("T")
                    var presTime = JSON.stringify(this.PresentTime)
                    var parseTime = JSON.parse(presTime)
                    var tempTime = parseTime.split("T")

                    if(TimeinLocal[0] != tempTime[0]){
                      ToOtpPage = true
                    }else{
                      ToDashBoard = true                      
                    }
                  }
              }
              var sessionDetails = JSON.stringify({"userID":this.UserName, "SessionId":response.data.sessionid})
              sessionStorage.setItem("sessionDetails", sessionDetails)
            }      

            if (this.remomber) {
              var t = JSON.stringify({"email":this.UserName,"password":this.Password})
              localStorage.setItem("UUID",t) //btoa(t)          
            } else {
              localStorage.removeItem("UUID")
            }

            if(ToDashBoard){
              window.location.href = '#/dashboard';
            }else if(ToOtpPage){
              window.location.href = '#/verifyotp';
            }else if(this.UserName == 'ceo@careercraffers.com'){
              window.location.href = '#/dashboard';
            }            
          } else {
            this.PageLoading=false
            swal({  
                  buttons: false,
                  text: response.data.Msg,
                  timer:2000,
                });            
          }      
      });      
    },
  },
  
};
</script>
<style>
 </style>
