<template>
<div class="header py-7 py-lg-7">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card">
        <div class="card-header">
          <div class="text-muted text-center mt-2 mb-3">
            <!-- <h1 style="color : black">CAREER CRAFFERS</h1> -->
            <img :src="logo" class="navbar-brand-img" alt="..." style="max-height:4rem; width:14rem" /> 
          </div>         
        </div>
      <div class="card-body px-lg-5 py-lg-5" v-if="flag == 'Before'">        
          <base-input
            formClasses="input-group-alternative mb-3"
            placeholder="User Name"
            addon-left-icon="ni ni-email-83"
            v-model="UserName"
          >
          </base-input>
          <div style="text-align:center !important">
          <button type="button" class="my-4 btn" style="background-color:#3297fa; color:white;" v-on:click="GenerateOTP">Generate OTP</button>
          </div>        
      </div>
        <div class="card-body px-lg-5 py-lg-5" v-if="flag == 'After'">
          <form role="form">
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="Enter OTP"
                addon-left-icon="ni ni-email-83"
                v-model="OTP"
              >
              </base-input>
              <div style="text-align:center !important">
                <button v-if="!PageLoading" type="button" class="my-4 btn" style="background-color:#3297fa; color:white;" v-on:click="Submit">Submit</button>
                <div v-if="PageLoading">
              <div class="my-4 btn" style="text-align: right;">      
                <div class="loader--ripple" style="width:57px !important">
                  <div></div>
                  <div></div>
                </div>
              </div>
             </div>
              </div> 
          </form>
        </div>
      </div>      
    </div>
  </div>
</div>
</template>
<script>
import helper from "../helper.js"
import swal from 'sweetalert';

export default {
  name: "login",
  data() {
    return {
      logo : "img/brand/logo-new.jpeg",
      PageLoading:false,
      UserName : "",
      flag : "Before",
      OTP : "",
      TimeNow : new Date()
    };
  },
  created(){
  },
 methods: {
    GenerateOTP() { 
        if(this.UserName == ""){
          swal({  
            text: "User Name is required",
            timer:2000, 
            buttons: false, 
          }); 
          return
        }   
        var Actionurl = "user/sendotp"
        const article = {"username":this.UserName};
        this.axios.post(helper.SERVICEURL+Actionurl, article)
        .then(response => {
          if(response.data.status) {
            if(response.data.Msg == "successfully Sent!"){
              this.flag = "After"
            }
          } else {
            swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,  
                }); 
          }      
      });      
    },
    Submit() {     
        var Actionurl = "user/validateotp"        
        if(isNaN(this.OTP)){
          swal({  
            text: "OTP must be a number",
            timer:2000, 
            buttons: false,
          }); 
          return false
        }else{
          var OtpNumber = parseInt(this.OTP)
        }
        const article = {"username":this.UserName, "otp":OtpNumber };
        this.PageLoading = true
        this.axios.post(helper.SERVICEURL+Actionurl, article)
        .then(response => {
          this.PageLoading = false
          if(response.data.status) {
            window.location.href = '#/dashboard';   
              var OTPData = JSON.stringify({"userID":this.UserName, "TimeNow":this.TimeNow})
              localStorage.setItem("OTPData", OTPData)              
          } else {
            swal({  
                  text: response.data.Msg,
                  timer:2000, 
                  buttons: false,
                }); 
          }      
      });      
    },
  },
  
};
</script>
<style></style>
